export const state = {
  bookingConversations: [],
  loading: false,
  error: null,
};

export const getters = {
  getBookingConversations: state => state.bookingConversations,
  isLoading: state => state.loading,
  getError: state => state.error,
};

export const mutations = {
  SET_BOOKING_CONVERSATIONS(state, conversations) {
    state.bookingConversations = conversations;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
};

export const actions = {
  async fetchBookingConversations({ commit }, propertyId) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get(
        `/api/v1/accounts/1/integrations/booking/conversations?property_id=${propertyId}`
      );
      const bookingConversations = response.data.data.conversations;
      commit('SET_BOOKING_CONVERSATIONS', bookingConversations);
    } catch (error) {
      commit(
        'SET_ERROR',
        'Errore nel recupero delle conversazioni di prenotazione'
      );
      console.error(
        'Errore nel recupero delle conversazioni di prenotazione:',
        error
      );
    } finally {
      commit('SET_LOADING', false);
    }
  },
  clearError({ commit }) {
    commit('CLEAR_ERROR');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
